import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import DirectionCompoent from "../../../containers/Maps/Direction";
import { EditOutlined } from "@ant-design/icons";
import { Card, Row, Col, Button, Divider } from "antd";
import moment from "moment";
import { rootAction } from "../../../store/slices/rootSlices";
import { useSelector, useDispatch } from "react-redux";
import savingOriginAndDestnationDistance from "../../../helper/distanceHelper";
import { distanceAction } from "../../../store/slices/distanceSlices";
import { orderCreateAction } from "../../../store/actions/orderAction";
import ApplyCouponModal from "../../Modal/ApplyCouponModal";
import { couponAction } from "../../../store/slices/couponslices";
import vippsLogo from "../../../assets/vippslogo.webp";
import Alert from "@mui/material/Alert";
import { apiUrl } from "../../../helper/endpoint";
import axios from "axios";

const SideCart = (props) => {
  let dispatch = useDispatch();
  let [disabled, setDisabled] = useState(false);
  let cart = useSelector((state) => state.cartReducer);
  let driveOut = useSelector((state) => state.distanceReducer);
  let [origin, setOrigin] = useState(null);
  let [driveOutCharge, setDriveOutcharge] = useState(0);
  let [destination, setDestination] = useState(null);
  let [response, setResponse] = useState(null);
  let [resDataApi, setResDataApi] = useState([]);
  let [openCouponModal, setopenCouponModal] = useState(false);
  let [couponValue, setCouponValue] = useState(0);
  let userLoction = useSelector((state) => state.locationReducer);
  let root = useSelector((state) => state.rootReducer);
  let customer = useSelector((state) => state.customerReducer);
  let couponReducer = useSelector((state) => state.couponReducer);
  let [isOrderCreated, setisOrderCreated] = useState(false);
  let resId = localStorage.getItem("resId");
  const [selectedTime, setSelectedTime] = useState(""); // State for selected time

  const closingTime = localStorage.getItem("closingTime");
  const tdDriveOutDelay = localStorage.getItem("tdDriveOutDelay");
  const tdTakeAwayDelay = localStorage.getItem("tdTakeAwayDelay");

  let [btnCardValue, setbtnCardValue] = useState("Pay");
  let [btnTidyPay, setbtnTidyPay] = useState("Pay");

  let navigateRoute = useNavigate();
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  let navigate = useNavigate();

  useEffect(() => {
    if (
      isNaN(cart.totalPrice) ||
      isNaN(driveOutCharge) ||
      isNaN(restaurant.tdServiceCharge) ||
      isNaN(cart.discountPrice)
    ) {
      navigateRoute("/", { replace: true });
    }
  }, [
    cart.totalPrice,
    driveOutCharge,
    restaurant.tdServiceCharge,
    cart.discountPrice
  ]);

  let directionsCallback = useCallback(
    (response) => {
      setResponse(response);
    },
    [response]
  );

  const onClickCoupon = () => {
    setopenCouponModal(true);
  };

  useEffect(() => {
    if (isOrderCreated && couponValue >= getTotalPrice()) {
      navigate("/orderstatuscoupon", {
        replace: true
      });
    }
  }, [isOrderCreated, root, cart]);

  const updateCouponValue = (value) => {
    if (value > 0) {
      udateCouponValueHelperFn(value);
    }
  };

  const udateCouponValueHelperFn = (value) => {
    let totalPrice = getTotalPrice();

    if (totalPrice > value) {
      setCouponValue(value);
    } else {
      setCouponValue(totalPrice);
      setbtnCardValue("Process Order");
    }
  };
  useEffect(() => {
    if (couponReducer.couponValue > 0) {
      udateCouponValueHelperFn(couponReducer.couponValue);
    }
  }, [
    couponReducer.couponValue,
    cart.totalPrice,
    restaurant.tdServiceCharge,
    cart.discountPrice
  ]);

  const onCloseApplycouponModal = () => {
    setopenCouponModal(false);
    dispatch(couponAction.resetFetchedState());
  };

  useEffect(() => {
    if (root.orderType == "Drive Out")
      if (response) {
        let distance = savingOriginAndDestnationDistance(response);
        dispatch(distanceAction.saveDistance(distance));
      }
  }, [response, root.orderType]);

  useEffect(() => {}, [props.isEnablePay, props.orderType]);

  useEffect(() => {
    if (root.orderType == "Drive Out") {
      let fixedDriveOutDistance = restaurant.tdFlatDriveOutDistance
        ? restaurant.tdFlatDriveOutDistance
        : 0;

      let fixedDrivoutCharge = restaurant.tdFlatRateDriveOut
        ? restaurant.tdFlatRateDriveOut
        : 0;
      let restaurantToCusDistance = Number(driveOut.disRestToCus);
      if (fixedDriveOutDistance >= restaurantToCusDistance) {
        setDriveOutcharge(fixedDrivoutCharge);
      } else {
        let additnalDrivoutDistance =
          restaurantToCusDistance - fixedDriveOutDistance;
        let additionalDriveOutCharge =
          +fixedDrivoutCharge +
          Number(additnalDrivoutDistance) *
            Number(restaurant.tdExtraRateDriveOut);
        setDriveOutcharge(additionalDriveOutCharge);
      }
    } else {
      // console.log("---setting driveout charge to 0----");
      setDriveOutcharge(0);
    }
  }, [driveOut, restaurant, cart.driveOut]);
  useEffect(() => {}, [driveOutCharge]);
  useEffect(() => {
    setOrigin({
      lat: restaurant.resLatitude,
      lng: restaurant.resLongitude
    });
  }, [restaurant]);

  const format = "HH:mm";
  const defaultTime = moment().add(
    root.orderType === "Drive Out" ? tdDriveOutDelay : tdTakeAwayDelay,
    "minutes"
  );
  const closingTimeMoment = moment(closingTime, format);

  const handleTimeChange = (time) => {
    if (time) {
      const selectedTimeFormatted = time.format(format);

      // Ensure the selected time doesn't exceed the closing time
      if (time.isSameOrBefore(closingTimeMoment)) {
        setSelectedTime(selectedTimeFormatted);
      } else {
        alert("The selected time exceeds the closing time.");
      }
    } else {
      setSelectedTime(defaultTime.format(format));
    }
  };

  const disabledTime = () => {
    return {
      disabledHours: () => {
        const closingHour = closingTimeMoment.hour();
        const currentHour = moment().hour();
        const hours = [];

        // Disable hours after the closing hour
        for (let i = closingHour + 1; i <= 23; i++) {
          hours.push(i);
        }

        // Disable hours before the current time if it's today
        if (moment().isSame(closingTimeMoment, "day")) {
          for (let i = 0; i < currentHour; i++) {
            hours.push(i);
          }
        }

        return hours;
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === closingTimeMoment.hour()) {
          const minutes = [];
          for (let i = closingTimeMoment.minute() + 1; i < 60; i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      }
    };
  };
  // const handleTimeChange = (time) => {
  //   setSelectedTime(time ? time.format(format) : defaultTime.format(format));
  // };

  const requestedTime = "23:30";
  // const requestedTime = selectedTime || defaultTime.format(format);

  let onCreateOrder = (cart, paymentMode) => {
    // console.log("cart", cart);
    if (root.orderType === "Drive Out" && userLoction.longitude === "") {
      alert("Del posisjonen din med TreeDrive");
      return;
    }

    setDisabled(true);

    dispatch(
      orderCreateAction(
        cart,
        restaurant._id,
        userLoction,
        driveOut,
        customer,
        root.orderType,
        couponReducer.appliedCouponCode,
        paymentMode,
        requestedTime
      )
    )
      .then(() => {
        setDisabled(false);
        setisOrderCreated(true);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  function getTotalPrice() {
    let totalPrice = (
      cart.totalPrice +
      (root.orderType == "Drive Out" ? +driveOutCharge : 0) +
      Number(restaurant.tdServiceCharge) -
      Number(cart.discountPrice)
    ).toFixed(2);

    return totalPrice;
  }

  useEffect(() => {
    setDestination({
      lat: userLoction.latitude,
      lng: userLoction.longitude
    });
  }, [userLoction]);

  useEffect(() => {
    async function fetchData() {
      let payloadData = {
        branchId: resId
      };
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/treeDrive/appdriver/allavailbledrivers`,
          payloadData
        );
        let resData = response.data.data;
        setResDataApi(resData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Card bordered={false} className="cardSize">
        <Row className="formHeader justify-content-between">
          <Col className="d-flex align-items-center justify-content-center">
            Innbetaling
          </Col>
          {/* <Col>
            {" "}
            <a
              className="editCartBtn"
              onClick={() => {
                console.log("Before dispatch:", customer);
                dispatch(rootAction.setOrderType(root.orderType));
                navigate("/products");
              }}
            >
              Rediger rekkefølge
            </a>
          </Col> */}
          <Col>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                console.log("Before dispatch:", customer);
                dispatch(rootAction.setOrderType(root.orderType));
                navigate("/products");
              }}
              style={{
                borderRadius: "8px",
                backgroundColor: "#1890ff",
                color: "#fff",
                fontWeight: "600",
                display: "flex",
                alignItems: "center"
              }}
            >
              Rediger rekkefølge
            </Button>
          </Col>
        </Row>
        <div className="px-3">
          <Divider plain className="pt-3">
            Se gjennom bestillingen din
          </Divider>
          <div className="d-flex flex-column" id="finalCart">
            {cart.cartItems.length &&
              cart.cartItems.map((sin, index) => {
                return (
                  <div className="payment-card" key={sin._id + "" + index}>
                    <Row className="pt-3">
                      <Col span={12}>
                        <h4 className="itemName">{sin.name}</h4>
                      </Col>
                      <Col span={6} className="d-flex justify-content-center">
                        <p className="mb-0">*{sin.quantity}</p>
                      </Col>
                      <Col span={6} className="d-flex justify-content-end">
                        <p className="itemName">
                          Kr.{(sin.incPrice * sin.quantity).toFixed(2)}
                        </p>
                      </Col>
                    </Row>

                    {sin.extraItems == "" ? (
                      ""
                    ) : (
                      <>
                        {sin.extraItems.length &&
                          sin.extraItems.map((sinElem, index) => {
                            return (
                              <Row
                                className="pt-3"
                                key={sinElem._id + "" + index}
                              >
                                <Col span={12}>
                                  <p className="itemName">{sinElem.name}</p>
                                </Col>
                                <Col span={12}>
                                  <p className="itemName billText">
                                    Kr. {sinElem.incPrice.toFixed(2)}
                                  </p>
                                </Col>
                              </Row>
                            );
                          })}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <Divider plain className="pt-3">
            Fakturadetaljer
          </Divider>
          <Row className="pt-0 mt-3">
            <Col span={12}>
              <h6 className="itemTotal">Subtotal</h6>
            </Col>
            <Col span={12}>
              <h6 className="itemTotal billText text-right">
                {(
                  cart.totalPrice -
                  (cart.totalPrice - (100 * cart.totalPrice) / (100 + 15))
                ).toFixed(2)}
              </h6>
            </Col>
            <Col span={12}>
              <h6 className="itemTotal">MVA</h6>
            </Col>
            <Col span={12}>
              <h6 className="itemTotal billText  text-right">
                {(
                  cart.totalPrice -
                  (100 * cart.totalPrice) / (100 + 15)
                ).toFixed(2)}
              </h6>
            </Col>
            {cart.discountPrice > 0 && (
              <>
                <Col span={12}>
                  <h6 className="itemTotal">
                    Rabatt({cart.discountPercent + "%"})
                  </h6>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText text-right">
                    {cart.discountPrice.toFixed(2)}
                  </h6>
                </Col>
              </>
            )}
            {driveOutCharge > 0 && (
              <>
                <Col span={12}>
                  <p className="itemTotal">
                    Utkjøring Pris ({driveOut.disRestToCus}Km)
                  </p>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText text-right">
                    {(driveOutCharge / 1.25).toFixed(2)}
                  </h6>
                </Col>

                <Col span={12}>
                  <p className="itemTotal">Utkjøring mva</p>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText">
                    {((driveOutCharge / 1.25) * 0.25).toFixed(2)}
                  </h6>
                </Col>
              </>
            )}
            {restaurant.tdServiceCharge > 0 && (
              <>
                <Col span={12}>
                  <h6 className="itemTotal billText">App-avgift</h6>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText text-right">
                    {restaurant.tdServiceCharge / 1.25}
                  </h6>
                </Col>
                <Col span={12}>
                  <p className="itemTotal">App-av. mva</p>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText text-right">
                    {(restaurant.tdServiceCharge / 1.25) * 0.25}
                  </h6>
                </Col>
              </>
            )}
            {couponValue > 0 && (
              <>
                <Col span={12}>
                  <p className="itemTotal">Gavekortkode brukt</p>
                </Col>
                <Col span={12}>
                  <h6 className="itemTotal billText text-right">
                    {-couponValue}
                  </h6>
                </Col>
              </>
            )}
          </Row>{" "}
          <Divider plain>Regning å betale</Divider>
          <Row className="pt-0 my-3">
            <Col span={12}>
              <h4 className="itemTotal">Total</h4>
            </Col>
            <Col span={12}>
              <h4 className="itemTotal billText text-right">
                Kr.{" "}
                {(
                  cart.totalPrice +
                  (root.orderType == "Drive Out" ? +driveOutCharge : 0) +
                  Number(restaurant.tdServiceCharge) -
                  Number(cart.discountPrice) -
                  Number(couponValue)
                ).toFixed(2)}
              </h4>
            </Col>
          </Row>
        </div>
        {root.orderType == "Drive Out" ? (
          <>
            {!resDataApi.driveMySelf && !resDataApi.driverAvailable ? (
              <Row>
                <p className="alertInfo">
                  *På grunn av at sjåføren ikke er tilgjengelig, kan vi ikke
                  fortsette med Utkjørings Ordrer.
                </p>
              </Row>
            ) : (
              <>
                <Row>
                  {" "}
                  {restaurant.minimumOrder <= cart.totalPrice ? (
                    <>
                      {cart.totalPrice && (
                        <Button
                          type="primary"
                          className="saveBtn"
                          onClick={onClickCoupon}
                          block
                          disabled={disabled || !props.isEnablePay}
                        >
                          {!couponValue && <span>Bruk Agentkort</span>}

                          {couponValue > 0 && (
                            <div className="coupon">
                              Kr. {couponValue} Kode brukt <br />
                            </div>
                          )}
                        </Button>
                      )}
                      {couponValue > 0 && (
                        <p>
                          <i>
                            {" "}
                            Klikk på Behandle bestilling-knappen for å bestille.
                          </i>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {root.orderType === "Drive Out" ? (
                        <>
                          {resDataApi.driveMySelf &&
                          resDataApi.driverAvailable ? (
                            <Row>
                              <i>
                                *På grunn av at sjåføren ikke er tilgjengelig,
                                kan vi ikke fortsette med Utkjørings Ordrer.
                              </i>
                            </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {cart.totalPrice && (
                            <Button
                              type="primary"
                              className="saveBtn"
                              onClick={onClickCoupon}
                              block
                              disabled={disabled || !props.isEnablePay}
                            >
                              {!couponValue && <span>Bruk Agentkort</span>}

                              {couponValue > 0 && (
                                <div className="coupon">
                                  Kr. {couponValue} Kode brukt <br />
                                </div>
                              )}
                            </Button>
                          )}
                          {couponValue > 0 && (
                            <p>
                              <i>Klikk Behandle bestilling for å bestille.</i>
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Row>
                <div className="mt-3">
                  {restaurant.tdDistance >= driveOut.disRestToCus ||
                  root.orderType === "Take Away" ? (
                    <Row className="justify-content-between">
                      <Col span={24} className="payCard p-0">
                        {restaurant.minimumOrder <= cart.totalPrice ? (
                          <div className="d-flex">
                            {restaurant.webpayment != "none" ? (
                              <>
                                {restaurant.webpayment === "tidypay" ? (
                                  <Button
                                    type="primary"
                                    className="w-100 saveBtn "
                                    block
                                    disabled={disabled || !props.isEnablePay}
                                    onClick={() =>
                                      onCreateOrder(cart, "TDIPay")
                                    }
                                  >
                                    {btnTidyPay}
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    className="w-100 saveBtn "
                                    block
                                    disabled={disabled || !props.isEnablePay}
                                    onClick={() =>
                                      onCreateOrder(cart, "Dintero")
                                    }
                                  >
                                    {btnCardValue}
                                  </Button>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {restaurant.isTdvipps === true ? (
                              <Button
                                type="primary"
                                className="w-100 saveBtn btnVipps "
                                block
                                disabled={disabled || !props.isEnablePay}
                                onClick={() => onCreateOrder(cart, "tdVipps")}
                              >
                                <img src={vippsLogo} width="50px" />
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <>
                            {root.orderType === "Drive Out" ? (
                              <Alert severity="error">
                                Minimumsbestillingen må være{" "}
                                <b>Kr. {restaurant.minimumOrder}</b>. Legg til
                                flere varer for å legge inn bestillingen.
                              </Alert>
                            ) : (
                              <div className="d-flex">
                                {restaurant.isTdvipps === true ? (
                                  <Button
                                    type="primary"
                                    className="w-100 saveBtn btnVipps "
                                    block
                                    disabled={disabled || !props.isEnablePay}
                                    onClick={() =>
                                      onCreateOrder(cart, "tdVipps")
                                    }
                                  >
                                    <img
                                      alt="vipps logo"
                                      src={vippsLogo}
                                      width="50px"
                                    />
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {restaurant.webpayment != "none" ? (
                                  <>
                                    {restaurant.webpayment === "tidypay" ? (
                                      <Button
                                        type="primary"
                                        className="w-100 saveBtn "
                                        block
                                        disabled={
                                          disabled || !props.isEnablePay
                                        }
                                        onClick={() =>
                                          onCreateOrder(cart, "TDIPay")
                                        }
                                      >
                                        {btnTidyPay}
                                      </Button>
                                    ) : (
                                      <Button
                                        type="primary"
                                        className="w-100 saveBtn "
                                        block
                                        disabled={
                                          disabled || !props.isEnablePay
                                        }
                                        onClick={() =>
                                          onCreateOrder(cart, "Dintero")
                                        }
                                      >
                                        {btnCardValue}
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Alert severity="error" className="mt-3">
                      Posisjonen din er for langt for levering!
                    </Alert>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <Row>
              {" "}
              {restaurant.minimumOrder <= cart.totalPrice ? (
                <>
                  {cart.totalPrice && (
                    <Button
                      type="primary"
                      className="saveBtn"
                      onClick={onClickCoupon}
                      block
                      disabled={disabled || !props.isEnablePay}
                    >
                      {!couponValue && <span>Bruk Agentkort</span>}

                      {couponValue > 0 && (
                        <div className="coupon">
                          Kr. {couponValue} Kode brukt <br />
                        </div>
                      )}
                    </Button>
                  )}
                  {couponValue > 0 && (
                    <p>
                      <i>
                        {" "}
                        Klikk på Behandle bestilling-knappen for å bestille.
                      </i>
                    </p>
                  )}
                </>
              ) : (
                <>
                  {root.orderType === "Drive Out" ? (
                    <>
                      {resDataApi.driveMySelf && resDataApi.driverAvailable ? (
                        <Row>
                          <i>
                            *På grunn av at sjåføren ikke er tilgjengelig, kan
                            vi ikke fortsette med Utkjørings Ordrer.
                          </i>
                        </Row>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {cart.totalPrice && (
                        <Button
                          type="primary"
                          className="saveBtn"
                          onClick={onClickCoupon}
                          block
                          disabled={disabled || !props.isEnablePay}
                        >
                          {!couponValue && <span>Bruk Agentkort</span>}

                          {couponValue > 0 && (
                            <div className="coupon">
                              Kr. {couponValue} Kode brukt <br />
                            </div>
                          )}
                        </Button>
                      )}
                      {couponValue > 0 && (
                        <p>
                          <i>Klikk Behandle bestilling for å bestille.</i>
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
            <div className="mt-3">
              {restaurant.tdDistance >= driveOut.disRestToCus ||
              root.orderType === "Take Away" ? (
                <Row className="justify-content-between">
                  <Col span={24} className="payCard p-0">
                    {restaurant.minimumOrder <= cart.totalPrice ? (
                      <div className="d-flex">
                        {restaurant.webpayment != "none" ? (
                          <>
                            {restaurant.webpayment === "tidypay" ? (
                              <Button
                                type="primary"
                                className="w-100 saveBtn "
                                block
                                disabled={disabled || !props.isEnablePay}
                                onClick={() => onCreateOrder(cart, "TDIPay")}
                              >
                                {btnTidyPay}
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                className="w-100 saveBtn "
                                block
                                disabled={disabled || !props.isEnablePay}
                                onClick={() => onCreateOrder(cart, "Dintero")}
                              >
                                {btnCardValue}
                              </Button>
                            )}
                          </>
                        ) : (
                          ""
                        )}{" "}
                        {restaurant.isTdvipps === true ? (
                          <Button
                            type="primary"
                            className="w-100 saveBtn btnVipps "
                            block
                            disabled={disabled || !props.isEnablePay}
                            onClick={() => onCreateOrder(cart, "tdVipps")}
                          >
                            <img src={vippsLogo} width="50px" />
                          </Button>
                        ) : (
                          ""
                          // <p>Komal</p>
                        )}
                      </div>
                    ) : (
                      <>
                        {root.orderType === "Drive Out" ? (
                          <Alert severity="error">
                            Minimumsbestillingen må være{" "}
                            <b>Kr. {restaurant.minimumOrder}</b>. Legg til flere
                            varer for å legge inn bestillingen.
                          </Alert>
                        ) : (
                          <div className="d-flex">
                            {restaurant.isTdvipps === true ? (
                              <Button
                                type="primary"
                                className="w-100 saveBtn btnVipps "
                                block
                                disabled={disabled || !props.isEnablePay}
                                onClick={() => onCreateOrder(cart, "tdVipps")}
                              >
                                <img src={vippsLogo} width="50px" />
                              </Button>
                            ) : (
                              ""
                            )}
                            {restaurant.webpayment != "none" ? (
                              <>
                                {restaurant.webpayment === "tidypay" ? (
                                  <Button
                                    type="primary"
                                    className="w-100 saveBtn "
                                    block
                                    disabled={disabled || !props.isEnablePay}
                                    onClick={() =>
                                      onCreateOrder(cart, "TDIPay")
                                    }
                                  >
                                    {btnTidyPay}
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    className="w-100 saveBtn "
                                    block
                                    disabled={disabled || !props.isEnablePay}
                                    onClick={() =>
                                      onCreateOrder(cart, "Dintero")
                                    }
                                  >
                                    {btnCardValue}
                                  </Button>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              ) : (
                <Alert severity="error" className="mt-3">
                  Posisjonen din er for langt for levering!
                </Alert>
              )}
            </div>
          </>
        )}
      </Card>
      {root.orderType == "Drive Out" && origin && destination ? (
        <DirectionCompoent
          origin={origin}
          destination={destination}
          directionsCallback={directionsCallback}
        />
      ) : (
        ""
      )}
      {openCouponModal && (
        <ApplyCouponModal
          onCloseApplycouponModal={onCloseApplycouponModal}
          branchId={restaurant._id}
          updateCouponValue={updateCouponValue}
        />
      )}
    </>
  );
};
export default SideCart;
