import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCouponValue } from "../../store/actions/couponAction";

function ApplyCouponModal(props) {
  console.log("eMarket", props);
  const [show, setShow] = useState(true);
  const [couError, setCouError] = useState("");
  const [loading, setLoading] = useState(false);

  const couponReducer = useSelector((state) => state.couponReducer);
  const couponRef = useRef();
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    props.onCloseApplycouponModal();
  };

  useEffect(() => {
    const { couponValue, isFetched } = couponReducer;

    if (isFetched) {
      if (couponValue) {
        props.updateCouponValue(couponValue);
        props.onCloseApplycouponModal();
      } else {
        setCouError("Koden er ugyldig eller utløpt");
      }
    }
  }, [couponReducer, props]);

  const onClickCoupon = async () => {
    const couponCode = couponRef.current.value.trim();
    console.log("couponCode", couponCode);

    if (!couponCode) {
      setCouError("Vennligst skriv inn en gavekortkode");
      return;
    }
    if (couponCode === couponReducer.appliedCouponCode) {
      setCouError("Denne koden er allerede i bruk.");
      return;
    }

    setCouError("");
    setLoading(true);

    try {
      await dispatch(
        getCouponValue({
          coupanCode: couponCode,
          branchId: props.branchId,
          fromWhere: props.type
        })
      );
    } catch (error) {
      console.error("Error occurred:", error);
      setCouError("Noe gikk galt. Prøv igjen senere.");
    } finally {
      setLoading(false);
    }
  };
  // const deleteCoupon = () => {
  //   // Reset the applied coupon code and value
  //   setAppliedCouponCode(null);
  //   setCouponValueLocal(null);
  //   localStorage.removeItem("appliedCouponCode"); // Remove coupon from localStorage
  //   setCouError(""); // Optionally reset any errors related to coupon
  //   console.log("Kupong fjernet.");
  // };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "black" }}>Bruk Agentkort</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="grow" style={{ color: "black" }} />
            </div>
          ) : (
            <Form.Group className="mb-3" controlId="couponInput">
              <Form.Control
                type="text"
                ref={couponRef}
                placeholder={
                  couponReducer.appliedCouponCode
                    ? "Lim inn ny kode"
                    : "Lim inn koden din"
                }
                autoFocus
              />
              {couError && (
                <Form.Text className="text-danger">{couError}</Form.Text>
              )}
            </Form.Group>
          )}
        </Form>
        {couponReducer.appliedCouponCode && (
          <div
            style={{
              padding: "16px",
              backgroundColor: "#f9f9f9",
              border: "1px solid #ddd",
              borderRadius: "8px",
              marginTop: "16px"
            }}
          >
            <h4 style={{ margin: 0, color: "#333", fontWeight: "bold" }}>
              Gjeldende brukt kupongkode
            </h4>
            <p style={{ margin: "8px 0", color: "#555", fontSize: "14px" }}>
              <strong>Kupongkode:</strong>{" "}
              {couponReducer.appliedCouponCode || "None"}
            </p>
            {/* <button
              className="text-white font-14 border-radius-5"
              onClick={() => deleteCoupon()}
              style={{
                backgroundColor: "#e74c3c",
                border: "none",
                padding: "8px 16px",
                cursor: "pointer"
              }}
            >
              Slett kupong
            </button> */}
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={onClickCoupon}>
            {couponReducer.appliedCouponCode ? "Endre kode" : "Bruk kode"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ApplyCouponModal;
