import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { isMobile } from "react-device-detect";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { userAction } from "../../store/slices/userslices";

import axios from "axios";

import { FloatingLabel, Button, Form, Alert } from "react-bootstrap";
import { Row, Col, Divider } from "antd";

import circleLogin from "../../assets/circleLogin.png";
import pinLogin from "../../assets/pinLogin.png";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

export default function Login() {
  let user = useSelector((state) => state.userReducer);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("47");

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    if (user.token) {
      navigate("/home");
    }
  }, []);

  const handleCountryCodeChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let password = e.target.inputPassword.value;

    let inputFields = [phoneNumber, password];

    if (!validateForm(inputFields)) {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg("Please fill all the required fields!");
    } else {
      if (phoneNumber.length > 10 || phoneNumber.length < 8) {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMsg("Please enter a valid Phone number!");
        return;
      }

      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg("Vennligst vent...");

      //try{

      let formData = {
        inputRequestType: "post",
        phone: phoneNumber,
        password: password,
        countryCode: selectedCountryCode
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/applogin`, formData)
        .then((response) => {
          localStorage.setItem("token", response.data.data.token);

          if (response.data.status == 200) {
            setShowAlert(true);
            setAlertType("success");
            setAlertMsg("Logget på vellykket, omdirigerer til profilen din.");

            let userPayload = {
              email: response.data.data.user.email,
              phone: response.data.data.user.phoneNumber,
              lastName: response.data.data.user.lastName,
              firstName: response.data.data.user.firstName,
              countryCode: response.data.data.user.countryCode,
              id: response.data.data.user._id,
              userReferralCode: response.data.data.user.userReferralCode,
              token: response.data.data.user.token,
              pointsEarned: response.data.data.user.pointsEarned,
              profileImage: response.data.data.user.profileImage
            };
            localStorage.setItem("referralCode", userPayload.userReferralCode);
            localStorage.setItem("token", userPayload.token);
            localStorage.setItem("userId", userPayload.id);
            localStorage.setItem("userFirstName", userPayload.firstName);
            localStorage.setItem("userLastName", userPayload.lastName);
            localStorage.setItem("userEmail", userPayload.email);
            localStorage.setItem("userCountryCode", userPayload.countryCode);
            localStorage.setItem("userPhone", userPayload.phone);
            localStorage.setItem("userPoints", userPayload.pointsEarned);
            localStorage.setItem("profileImage", userPayload.profileImage);
            console.log("response.data", response.data);
            dispatch(userAction.saveUser(userPayload), (window.location = "/"));
          } else {
            // console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("---error user---", error.message);
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(
            "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din f�r du sender inn skjemaet."
          );
        });
    }
  };
  const commonCardLogin = () => {
    return (
      <>
        <Row
          className="d-flex flex-column align-items-center justify-content-center logBox"
          style={{ borderRadius: "20px" }}
        >
          <Col span={24} className="text-center width-100">
            {" "}
            <h4 class="font-18 font-700">EzyGo</h4>
            <p className="mb-0">Mat | Skjønnhet | Shopping</p>
            <h4
              class="font-18 font-700 mt-1 d-flex align-items-center justify-content-center"
              style={{ color: "#0066CC " }}
            >
              <img src={circleLogin} loading="lazy" />
              &nbsp; Forenklet for deg!&nbsp;
              <img src={pinLogin} loading="lazy" />
            </h4>
            <Divider>Logg In</Divider>
          </Col>
          <Col span={24}>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Col className="d-flex">
                  <Form.Select
                    style={{ width: "30%" }}
                    aria-label="Velg landskode"
                    name="inputCountryCode"
                    onChange={handleCountryCodeChange}
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>
                  <FloatingLabel
                    style={{ width: "100%" }}
                    controlId="floatingInputGrid"
                    label="Telefonnummer*"
                  >
                    <Form.Control
                      required
                      type="number"
                      placeholder="Telefonnummer"
                      name="inputPhone"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>
              <Form.Group className="row mb-3" controlId="formBasicPassword">
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="Passord*">
                    <Form.Control
                      required
                      type="password"
                      placeholder="Passord"
                      name="inputPassword"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>
              <div className="text-right">
                <u>
                  <NavLink to="/reset" className="col-1">
                    Glemt passordet?
                  </NavLink>
                </u>
              </div>
              {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
              <Button
                variant="primary"
                type="submit"
                className="width-100 mt-3 py-2 border-radius-10"
              >
                Logg Inn
              </Button>{" "}
              <p className="mt-2">
                Ny bruker&nbsp;
                <NavLink to="/signup" className=" col-1">
                  Registrer deg!
                </NavLink>
              </p>
              <p className="text-center">
                Ved å fortsette godtar du vår
                <br />
                <a href="/terms">Vilkår og betingelser</a> and{" "}
                <a href="/privacy">Personvernerklæring</a>
              </p>
            </Form>
          </Col>
          <Col span={24}>
            <NavLink to="/home" style={{ color: "#0066CC" }}>
              {" "}
              <u>Fortsett som gjestebruker!</u>
            </NavLink>{" "}
          </Col>
        </Row>
      </>
    );
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col md={9} xl={6} className="pb-3 ">
              {commonCardLogin()}
            </Col>
          </div>
        </section>
      )}
      {isMobile && (
        <section
          className="loginBg p-2 d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          {commonCardLogin()}
        </section>
      )}
      {/* <BottomFooter /> */}
    </>
  );
}
